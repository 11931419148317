@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    --primary-text-color: var(--color-mud_black);
    --positive-color: var(--color-success);
    --negative-color: var(--color-error);
    --primary-border-color: var(--color-explosive);
    --placeholder-color: var(--color-explosive);
    --icon-color: var(--color-explosive);
    --icon-color-hover: var(--color-blackish);
    --primary-active-color: var(--color-basic_blue);

}

.dark {
    --primary-text-color: #d8d8d8;
    --positive-color: green;
    --negative-color: red;
    --primary-border-color: black;
    --placeholder-color: black;
    --icon-color: black;
    --icon-color-hover: grey;
    --primary-background-color: #484861;
    --primary-background-hover-color: #4b4e69;
    --link-color: var(--color-explosive);
}


.hacker_theme {
    --primary-text-color: #d8d8d8;
    --positive-color: green;
    --negative-color: red;
    --primary-border-color: black;
    --placeholder-color: black;
    --icon-color: black;
    --icon-color-hover: grey;
    --primary-background-color: #484861;
    --link-color: var(--color-explosive);
    --primary-background-hover-color: #4b4e69;
    --primary-color: #fe78c6;
    --primary-hover-color: #fe5ab9
}


.black {
    --primary-text-color: #d8d8d8;
    --positive-color: green;
    --negative-color: red;
    --primary-border-color: black;
    --placeholder-color: black;
    --icon-color: black;
    --icon-color-hover: grey;
    --primary-background-color: #484861;
    --primary-background-hover-color: #4b4e69;
    --link-color: var(--color-explosive);
}